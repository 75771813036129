import { Component, OnInit } from '@angular/core';
import { faBriefcaseMedical, faChild, faTruck, faFemale, faSmile } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-medical-services',
  templateUrl: './medical-services.component.html',
  styleUrls: ['./medical-services.component.scss'],
})
export class MedicalServicesComponent implements OnInit {
  faBriefcaseMedical = faBriefcaseMedical;
  faChild = faChild;
  faTruck = faTruck;
  faFemale = faFemale;
  faSmile = faSmile;
  constructor() {}
  public goTo(to: string): void {
    alert(to);
    window.location.host = to;
  }
  ngOnInit() {}
}
