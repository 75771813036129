import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-uber-uns',
  templateUrl: './uber-uns.component.html',
  styleUrls: ['./uber-uns.component.scss'],
})
export class UberUnsComponent implements OnInit {
  public photos: string[] = ['../../../assets/img/Kurierdienst1.jpg', '../../../assets/img/Kurierdienst2.jpg'];
  public currentPhoto: string = '';
  public currentIndex: number = 0;
  constructor() {}

  public ngOnInit(): void {
    this.currentPhoto = this.photos[this.currentIndex];
    this.toggelPhotos();
  }
  public toggelPhotos(): void {
    setTimeout(() => {
      ++this.currentIndex;
      if (this.currentIndex < this.photos.length) {
        this.currentPhoto = this.photos[this.currentIndex];
      } else {
        this.currentIndex = 0;
        this.currentPhoto = this.photos[this.currentIndex];
      }
      this.toggelPhotos();
    }, 7000);
  }
}
