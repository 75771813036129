import { Component, OnInit } from '@angular/core';
import { faBriefcaseMedical } from '@fortawesome/free-solid-svg-icons';
import { timeInterval } from 'rxjs/operators';

@Component({
  selector: 'app-neuigkeiten',
  templateUrl: './neuigkeiten.component.html',
  styleUrls: ['./neuigkeiten.component.scss'],
})
export class NeuigkeitenComponent implements OnInit {
  public faBriefcaseMedical: any = faBriefcaseMedical;
  public photos: string[] = ['../../../assets/img/umzug1.jpg', '../../../assets/img/umzug2.jpg'];
  public currentPhoto: string = '';
  public currentIndex: number = 0;
  constructor() {}

  public ngOnInit(): void {
    this.currentPhoto = this.photos[this.currentIndex];
    this.toggelPhotos();
  }
  public toggelPhotos(): void {
    setTimeout(() => {
      ++this.currentIndex;
      if (this.currentIndex < this.photos.length) {
        this.currentPhoto = this.photos[this.currentIndex];
      } else {
        this.currentIndex = 0;
        this.currentPhoto = this.photos[this.currentIndex];
      }
      this.toggelPhotos();
    }, 7000);
  }
}
