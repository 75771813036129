import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';

@Component({
  selector: 'app-lastentaxien',
  templateUrl: './lastentaxien.component.html',
  styleUrls: ['./lastentaxien.component.scss'],
})
export class LeistungenComponent implements OnInit, OnDestroy {
  constructor() {}

  public ngOnInit(): void {}
  public ngOnDestroy(): void {}
}
