import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { faBriefcaseMedical } from '@fortawesome/free-solid-svg-icons';
import { MatTabGroup } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
export interface PeriodicElement {
  name: string;
  position: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { position: 'Anfahrt', name: '29,00€' },
  { position: '1 km', name: '1,90€' },
  { position: '5 km', name: '9,50€' },
  { position: '10 km', name: '19,00€' },
  { position: '20 km', name: '36,00€' },
  { position: '30 km', name: '54,00€' },
  { position: '40 km', name: '88,00€' },
];
@Component({
  selector: 'app-sub-page-tap-content',
  templateUrl: './sub-page-tap-content.component.html',
  styleUrls: ['./sub-page-tap-content.component.scss'],
})
export class SubPageTapContentComponent implements OnInit, OnDestroy {
  public faBriefcaseMedical: any = faBriefcaseMedical;
  public photos: string[] = ['../../../assets/img/Lastentaxi1.jpg', '../../../assets/img/Lastentaxi2.jpg'];
  public currentPhoto: string = '';
  public currentIndex: number = 0;
  public displayedColumns: string[] = ['KM', 'Price'];
  public dataSource: PeriodicElement[] = ELEMENT_DATA;
  constructor(private route: ActivatedRoute) {}

  public ngOnInit(): void {
    this.currentPhoto = this.photos[this.currentIndex];
    this.toggelPhotos();
  }
  public toggelPhotos(): void {
    setTimeout(() => {
      ++this.currentIndex;
      if (this.currentIndex < this.photos.length) {
        this.currentPhoto = this.photos[this.currentIndex];
      } else {
        this.currentIndex = 0;
        this.currentPhoto = this.photos[this.currentIndex];
      }
      this.toggelPhotos();
    }, 7000);
  }
  public ngOnDestroy(): void {}
}
